import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import './App.css';
import HomePage from './pages/home/HomePage';
import RegisterPage from './pages/register/RegisterPage';
import LoginPage from './pages/login/LoginPage';
import ProfilePage from './pages/admin/screens/settings/ProfilePage';
import AdminLayout from './pages/admin/AdminLayout';
import Admin from './pages/admin/screens/Admin';
import NewClient from './pages/admin/screens/clients/NewClient';
import ManageClients from './pages/admin/screens/clients/ManageClients';
import EditClient from './pages/admin/screens/clients/EditClient';
import Translations from './pages/admin/screens/Translations';
import AboutUs from './pages/admin/screens/about/AboutUs';
import NewReview from './pages/admin/screens/reviews/NewReview';
import EditReview from './pages/admin/screens/reviews/EditReview';
import ManageReview from './pages/admin/screens/reviews/ManageReview';
import WebSiteSettingsPage from './pages/admin/screens/settings/WebSiteSettingsPage';
import StudioWorks from './pages/admin/screens/works/StudioWorks';

function App() {
  return (
    <div className="App font-montserratArmRegular">
      <Routes>
        <Route index path="/:lang?/" element={<HomePage />} />
        <Route path="/:lang?/register" element={<RegisterPage />} />
        <Route path="/:lang?/login" element={<LoginPage />} />
        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<Admin />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="translations" element={<Translations />} />
          <Route path="clients/new" element={<NewClient />} />
          <Route path="clients/manage" element={<ManageClients />} />
          <Route path="clients/manage/edit/:slug" element={<EditClient />} />
          <Route path="reviews/new" element={<NewReview />} />
          <Route path="reviews/manage" element={<ManageReview />} />
          <Route path="reviews/manage/edit/:slug" element={<EditReview />} />
          <Route path="websettings" element={<WebSiteSettingsPage />} />
          <Route path="usersettings" element={<ProfilePage />} />
          <Route path="studio-works" element={<StudioWorks />} />
        </Route>
      </Routes>
      <Toaster />
    </div>
  );
}

export default App;
