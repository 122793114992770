import React, { useEffect, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { locales } from './PageData';
import {
  MobileIcon, Nav,
  NavbarContainer,
  NavItem,
  NavLink,
  NavLogo,
  NavMenu
} from './navBar/NavbarElements';

import { images } from '../constants';
import { FaBars } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Header = ({ toggle, webSettings }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    navigate(`/${lng}`, { replace: true });
  };

  const changeNav = () => {
    if(window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const scrollProps = {
    activeClass: 'active',
    smooth: true,
    duration: 500,
    spy: true,
    exact: 'true',
    offset: -80
  };

  return (
    <Nav scrollNav={scrollNav}>
      <NavbarContainer>
        <NavLogo to="/" onClick={toggleHome}>
          <img className="w-full max-w-[50px] h-auto" src={images.Logo} alt="logo"/>
        </NavLogo>
        <MobileIcon onClick={toggle}>
          <FaBars/>
        </MobileIcon>
        <NavMenu>
          {webSettings.studioWorks && <NavItem>
            <NavLink {...scrollProps} navLinkItem={webSettings.studioWorks}/>
          </NavItem>}
        </NavMenu>
        <div className="flex flex-row justify-center grow-[1]">
          <NavMenu>
            {webSettings.aboutUs && <NavItem>
              <NavLink {...scrollProps} navLinkItem={webSettings.aboutUs}/>
            </NavItem>}
            {webSettings.clients && <NavItem>
              <NavLink {...scrollProps} navLinkItem={webSettings.clients}/>
            </NavItem>}
            {webSettings.reviews && <NavItem>
              <NavLink {...scrollProps} navLinkItem={webSettings.reviews}/>
            </NavItem>}
          </NavMenu>
          <NavMenu>
            {webSettings.instagram && webSettings.instagram.href && <NavItem>
              <NavLink {...scrollProps} navLinkItem={webSettings.instagram}/>
            </NavItem>}
            {webSettings.facebook && webSettings.facebook.href && <NavItem>
              <NavLink {...scrollProps} navLinkItem={webSettings.facebook}/>
            </NavItem>}
          </NavMenu>
        </div>
        <NavMenu>
          <NavItem></NavItem>
        </NavMenu>
        <NavMenu direction='flex-row'>
          {Object.keys(locales).map((locale) => (
            <li
              key={locale}
              onClick={() => changeLanguage(locale)}
              className={`text-center cursor-pointer uppercase group w-[40px] text-dark-hard last:border-0 border-r-2 ${i18n.language === locale ? 'font-bold' : 'font-normal'}`}>
              <p className='group-hover:font-bold'>{locales[locale].title}</p>
            </li>
          ))}
        </NavMenu>
      </NavbarContainer>
    </Nav>

  );
};

export default Header;
