import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import draftToHtml from 'draftjs-to-html';
import { getAboutUsData } from '../../../services/index/about';
import { toast } from 'react-hot-toast';
import ArticleDetailSkeleton from '../../articleDetail/components/ArticleDetailSkeleton';
import ErrorMessage from '../../../components/ErrorMessage';
import { motion, useInView } from 'framer-motion';

const About = () => {
  const sectionRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [aboutUsData, setAboutUsData] = useState(null);
  const isVisible = useInView(sectionRef);
  useEffect(() => {
    // Fetch aboutUss when the component mounts
    const fetchAboutUs = async () => {
      try {
        const { data, isLoading, isError } = await getAboutUsData();
        setAboutUsData(data);
        setIsLoading(isLoading);
        setIsError(isError);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };
    fetchAboutUs();
  }, []);

  return (
    <div id="aboutUs" ref={sectionRef} className={`overflow-x-hidden duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>

      {isLoading
        ? (
          <ArticleDetailSkeleton />
        )
        : isError
          ? (
            <ErrorMessage message="Couldn't fetch the aboutUs detail" />
          )
          : (<section className="mx-auto px-5 border-b-2 py-10 flex flex-col gap-8">
            <motion.div
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ amount: 0.1 }}
              initial={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.7 }} >
              {/* Your content goes here */}
              <h3 className="section-title element-left">{t('About Us')}</h3>
            </motion.div>
            <motion.div
              className={'w-full'}
              whileInView={{ opacity: 1 }}
              viewport={{ amount: 0.8 }}
              initial={{ opacity: 0 }}
              transition={{ duration: 1.2 }}>
              <div className="wysiwyg md:w-2/3 w-full mx-auto" dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(aboutUsData.bodies[i18n.language])) }}/>

            </motion.div>
          </section>

          )
      }
    </div>
  );
};

export default About;
