import React from 'react';
import MainLayout from '../../components/MainLayout';
import Clients from '../../components/clients/Clients';
import Hero from './container/Hero';
import About from './container/About';
import Reviews from '../../components/rewies/Reviews';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HomePage = () => {
  return (
    <MainLayout>
      <Hero />
      <About />
      <Clients />
      <Reviews />
      {/* <CTA /> */}
    </MainLayout>
  );
};

export default HomePage;
