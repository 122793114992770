import { HiOutlineCamera } from 'react-icons/hi';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { stables } from '../../../../constants';
import { createReview } from '../../../../services/index/reviews';
import { NavBtn } from '../../../../components/navBar/NavbarElements';
import { locales } from '../../../../components/PageData';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const ImageUpload = ({ photo, initialPhoto, handleFileChange, handleDeleteImage }) => (
  <label htmlFor="reviewPicture" className="w-full cursor-pointer">
    {photo
      ? (
        <img src={URL.createObjectURL(photo)} alt="Article cover" className="rounded-xl w-full max-w-[220px] object-contain"/>
      )
      : initialPhoto
        ? (
          <img src={initialPhoto} alt="Article cover" className="rounded-xl w-full max-w-[220px] object-contain"/>
        )
        : (
          <div className="w-full min-h-[200px] bg-blue-50/50 flex justify-center items-center">
            <HiOutlineCamera className="w-7 h-auto text-primary"/>
          </div>
        )}
    <input
      type="file"
      className="sr-only"
      id="reviewPicture"
      onChange={handleFileChange}
    />
    <button
      type="button"
      onClick={handleDeleteImage}
      className="w-fit bg-red-500 text-sm text-white font-MontserratArmSemiBold rounded-lg px-2 py-1 mt-5"
    >
      Delete Image
    </button>
  </label>
);

const NewReview = () => {
  const { slug } = useParams();
  const queryClient = useQueryClient();
  const userState = useSelector((state) => state.user);
  const [initialPhoto, setInitialPhoto] = useState(null);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [currentLng, setCurrentLng] = useState(Object.keys(locales)[0]);
  const [photo, setPhoto] = useState(null);
  const [formData, setFormData] = useState({ en: {}, ru: {}, hy: {} });
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      title: '',
      caption: '',
      photo: ''
    },
    mode: 'onChange'
  });

  const {
    mutate: mutateUpdateReviewDetail,
    isLoading: isLoadingUpdateReviewDetail
  } = useMutation({
    mutationFn: ({ newData, token }) => {
      return createReview({
        newData,
        token
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['reviews', slug]);
      toast.success('Review is updated');
      setTimeout((navigate('/admin/reviews/manage')), 800);
    },
    onError: (error) => {
      toast.error(error.message);
      console.log(error);
    }
  });

  useEffect(() => {
    setCurrentLng(i18n.language);
  }, [i18n.language]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPhoto(file);
  };

  const changeLanguage = async (data) => {
    setFormData({ ...formData, [currentLng]: data.data });
    setCurrentLng(data.locale);
    formData[data.locale] && reset({ title: formData[data.locale].title || '', caption: formData[data.locale].caption || '' });
  };

  const handleCreateReview = async (data) => {
    const newData = new FormData();

    // Check if there's a new photo, if so, append it
    if(photo) {
      newData.append('reviewPicture', photo);
    } else if(initialPhoto) {
      // If there's no new photo but there's an initial photo, fetch and append it
      const response = await fetch(stables.UPLOAD_FOLDER_BASE_URL + data?.photo);
      const blob = await response.blob();
      const file = new File([blob], initialPhoto, { type: blob.type });
      newData.append('reviewPicture', file);
    }

    // Create a new data object with the current language data merged
    const arr = { ...formData, [currentLng]: data };
    newData.append('document', JSON.stringify({ data: arr }));

    // Trigger the update using the optimized data
    mutateUpdateReviewDetail({
      newData,
      token: userState.userInfo.token
    });
  };

  const handleDeleteImage = () => {
    if(window.confirm('Do you want to delete your Review picture?')) {
      setInitialPhoto(null);
      setPhoto(null);
    }
  };

  return (
    <div>
      <section
        className="container mx-auto max-w-5xl flex flex-col px-5 py-5 lg:flex-row lg:gap-x-5 lg:items-start">
        <article className="flex-1">
          <ImageUpload
            photo={photo}
            initialPhoto={initialPhoto}
            handleFileChange={handleFileChange}
            handleDeleteImage={handleDeleteImage}
          />
          <div className='flex justify-end w-full my-4'>
            <div
              className="text-white items-center gap-y-5 lg:text-dark-hard flex flex-col lg:flex-row gap-x-2 font-MontserratArmSemiBold">
              <div className="relative group">
                <div className="flex flex-col items-center">
                  <NavBtn onClick={() => setProfileDropdown(!profileDropdown)}>
                    <span>{locales[currentLng].name}</span>
                    <MdKeyboardArrowDown/>
                  </NavBtn>
                  <div
                    className={`${
                      profileDropdown ? 'block' : 'hidden'
                    } lg:hidden transition-all duration-500 pt-4 absolute bottom-0 right-0 transform translate-y-full group-hover:block w-max`}
                  >
                    <ul
                      className="bg-dark-light text-center flex flex-col shadow-lg rounded-lg overflow-hidden w-full">
                      {Object.keys(locales).map((locale) => (
                        <NavBtn
                          key={locale}
                          onClick={handleSubmit((data) => changeLanguage({ data, locale }))}
                          className={`hover:bg-dark-hard hover:text-white px-4 py-2 text-dark-hard text-center cursor-pointer uppercase group ${currentLng === locale ? 'font-bold' : 'font-normal'}`}>
                          <p>{locales[locale].name}</p>
                        </NavBtn>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col mb-6 w-full">
            <label
              htmlFor="title"
              className="text-[#5a7184] font-MontserratArmSemiBold block"
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              {...register('title', {
                minLength: {
                  value: 1,
                  message: 'Title length must be at least 1 character'
                },
                required: {
                  value: false,
                  message: 'Title is required'
                }
              })}
              placeholder="Enter Caption"
              className={`placeholder:text-[#959ead] text-dark-hard mt-3 rounded-lg px-5 py-4 font-MontserratArmSemiBold block outline-none border ${
                errors.title ? 'border-red-500' : 'border-[#c3cad9]'
              }`}
            />
            {errors.title?.message && (
              <p className="text-red-500 text-xs mt-1">
                {errors.title?.message}
              </p>
            )}
          </div>
          <div className="flex flex-col mb-6 w-full">
            <label
              htmlFor="caption"
              className="text-[#5a7184] font-MontserratArmSemiBold block"
            >
              Caption
            </label>
            <textarea
              id="caption"
              rows='10'
              {...register('caption', {
                minLength: {
                  value: 1,
                  message: 'Caption length must be at least 1 character'
                },
                required: {
                  value: false,
                  message: 'Caption is required'
                }
              })}
              placeholder="Enter Caption"
              className={`placeholder:text-[#959ead] text-dark-hard mt-3 rounded-lg px-5 py-4 font-MontserratArmSemiBold block outline-none border resize-none ${
                errors.caption ? 'border-red-500' : 'border-[#c3cad9]'
              }`}
            />
            {errors.caption?.message && (
              <p className="text-red-500 text-xs mt-1">
                {errors.caption?.message}
              </p>
            )}
          </div>
          <button
            disabled={isLoadingUpdateReviewDetail}
            type="button"
            onClick={handleSubmit(handleCreateReview)}
            className="w-full bg-green-500 text-white hover:bg-green-500/90 font-MontserratArmSemiBold rounded-lg px-4 py-2 disabled:cursor-not-allowed disabled:opacity-70"
          >
            Create Review
          </button>
        </article>
      </section>
    </div>
  );
};

export default NewReview;
