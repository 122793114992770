import React from 'react';
const ClientCardSkeleton = ({ className }) => {
  return (
    <div className={`flex w-full border-b-2 border-slate-300 py-12 ${className} animate-pulse`} >
      {/* index */}
      <span
        className='element-left font-bold text-primary text-xl md:text-xl lg:text-2xl lg:min-w-[82px]
        min-w-[32px] text-right before:bg-slate-300' >00</span>

      <div className='w-full flex justify-between items-start' >
        <div className='flex md:flex-row sm:flex-col flex-col justify-evenly lg:text-start text-center lg:items-start items-center lg:p-0 mx-4 gap-4 w-full'>
          {/* title */}
          <div className="w-24 h-2 mt-4 bg-slate-300 rounded-lg" />
          {/* caption */}
          <div>
            <div className="w-56 h-2 mt-4 bg-slate-300 rounded-lg" />
            <div className="w-48 h-2 mt-4 bg-slate-300 rounded-lg" />
            <div className="w-52 h-2 mt-4 bg-slate-300 rounded-lg" />
          </div>
        </div>
        {/* image */}
        <div className='w-full max-w-[200px] object-center h-auto'>
          <div className="bg-slate-300 w-full max-h-[200px] h-screen object-center h-auto md:h-52 lg:h-48 xl:h-60" ></div>
        </div>
      </div>

    </div>

  );
};

export default ClientCardSkeleton;
