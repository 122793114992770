import React from 'react';
import { images } from '../constants';
import { FooterNavMenu, NavHref, NavItem, NavLink } from './navBar/NavbarElements';

const Footer = ({ webSettings }) => {
  const scrollProps = {
    activeClass: 'active',
    smooth: true,
    duration: 500,
    spy: true,
    exact: 'true',
    offset: -80
  };
  const currentYear = new Date().getFullYear();

  return (
    <section className="bg-dark-light">
      <footer className="container mx-auto flex lg:flex-nowrap flex-wrap justify-end px-5 py-10 w-100 md:gap-4 gap-8">
        <div className="w-full text-end flex flex-wrap justify-end">
          <div className="footer-element-container">
            <FooterNavMenu>
              {webSettings.studioWorks && <NavItem>
                <NavLink {...scrollProps} isFooter={true} navLinkItem={webSettings.studioWorks}/>
              </NavItem>}
              {webSettings.aboutUs && <NavItem>
                <NavLink {...scrollProps} isFooter={true} navLinkItem={webSettings.aboutUs}/>
              </NavItem>}
            </FooterNavMenu>
          </div>
          <div className="footer-element-container">
            <FooterNavMenu>
              {webSettings.clients && <NavItem>
                <NavLink {...scrollProps} isFooter={true} navLinkItem={webSettings.clients}/>
              </NavItem>}
              {webSettings.reviews && <NavItem>
                <NavLink {...scrollProps} isFooter={true} navLinkItem={webSettings.reviews}/>
              </NavItem>}
            </FooterNavMenu></div>
          <div className="footer-element-container">
            <FooterNavMenu>
              {webSettings.instagram && webSettings.instagram.href && <NavItem>
                <NavHref navLinkItem={webSettings.instagram}/>
              </NavItem>}
              {webSettings.facebook && webSettings.facebook.href && <NavItem>
                <NavHref navLinkItem={webSettings.facebook}/>
              </NavItem>}
              {webSettings.twitter && webSettings.twitter.href && <NavItem>
                <NavHref navLinkItem={webSettings.twitter}/>
              </NavItem>}
              {webSettings.youtube && webSettings.youtube.href && <NavItem>
                <NavHref navLinkItem={webSettings.youtube}/>
              </NavItem>}
            </FooterNavMenu>
          </div>
          <div className="footer-element-container min-w-[270px]">
            <FooterNavMenu>
              {webSettings.phone && <NavItem>
                <NavHref navLinkItem={webSettings.phone}/>
              </NavItem>}
              {webSettings.email && <NavItem>
                <NavHref navLinkItem={webSettings.email}/>
              </NavItem>}
            </FooterNavMenu>
          </div>
        </div>

        <div className="xl:w-1/5 lg:w-1/6 w-full lg:order-first order-last max-w-[70px] md:mr-auto md:mt-0 mt-4 mx-auto">
          <img
            src={images.Logo}
            alt="logo"
            className="mx-auto lg:mx-0"
          />
        </div>
      </footer>
      <div className="bg-primary py-1  md:flex flex-col items-center space-y-4 text-center">
        <p className="text-sm italic">
          &copy; {currentYear} MedaHouse Armenia - All rights reserved
        </p>
      </div>
    </section>
  );
};

export default Footer;
