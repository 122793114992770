import React, { useEffect, useMemo, useState } from 'react';
import ArticleDetailSkeleton from '../../../articleDetail/components/ArticleDetailSkeleton';
import ErrorMessage from '../../../../components/ErrorMessage';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import VideoPlayer from '../../../../video/VideoPlayer';
import { getStudioWorksData, updateStudioWorksData } from '../../../../services/index/studioWroks';
import WorkPhoto from './WorkPhoto';

const StudioWorks = () => {
  const userState = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [studioWorksData, setStudioWorksData] = useState(null);
  const [videoLink, setVideoLink] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchStudioWorks = async () => {
      try {
        const { data, isLoading, isError } = await getStudioWorksData();
        setStudioWorksData(data);
        setIsLoading(isLoading);
        setIsError(isError);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };
    // Fetch studioWorks when the component mounts
    fetchStudioWorks();
  }, []);

  const { register, handleSubmit } = useForm({
    mode: 'onChange',
    values: useMemo(() => {
      return {
        videoLink: studioWorksData?.src ? studioWorksData?.src : ''
      };
    }, [studioWorksData?.src])
  });

  const handleUpdateStudioWorks = async (data) => {
    let videoData = {};
    const htmlString = data.videoLink;
    if(htmlString) {
      // Create a temporary element (e.g., a div) to parse the HTML
      const tempElement = document.createElement('div');
      tempElement.innerHTML = htmlString;

      // Find the iframe element and get its src attribute value
      const iframeElement = tempElement.querySelector('iframe');
      const srcAttributeValue = iframeElement ? iframeElement.getAttribute('src') : null;
      const titleAttributeValue = iframeElement ? iframeElement.getAttribute('title') : '';
      if(srcAttributeValue) {
        videoData = { videoLink: srcAttributeValue, title: titleAttributeValue };
        try {
          await updateStudioWorksData({ updatedData: videoData, token: userState.userInfo.token });

          return toast.success('StudioWorks is updated');
        } catch (error) {
          toast.error(error.message);
          console.error(error);
        }
      } else {
        const error = 'embed code is incorrect';
        toast.error(error);
        console.log(error);
      }
    } else {
      const error = 'embed code is incorrect';
      toast.error(error);
      console.log(error);
    }
  };

  const handleVideoPreview = (data) => {
    const htmlString = data.videoLink;
    if(htmlString) {
      // Create a temporary element (e.g., a div) to parse the HTML
      const tempElement = document.createElement('div');
      tempElement.innerHTML = htmlString;

      // Find the iframe element and get its src attribute value
      const iframeElement = tempElement.querySelector('iframe');
      const srcAttributeValue = iframeElement ? iframeElement.getAttribute('src') : null;
      const titleAttributeValue = iframeElement ? iframeElement.getAttribute('title') : '';
      if(srcAttributeValue) {
        // Log the src attribute value
        setVideoLink({ videoLink: srcAttributeValue, title: titleAttributeValue });
      } else {
        const error = 'embed code is incorrect';
        toast.error(error);
        console.log(error);
      }
    } else {
      const error = 'embed code is incorrect';
      toast.error(error);
      console.log(error);
    }
  };
  return (
    <>
      {isLoading
        ? (
          <ArticleDetailSkeleton />
        )
        : isError
          ? (
            <ErrorMessage message="Couldn't fetch the studio works detail" />
          )
          : (<section className="container mx-auto flex flex-col items-stretch px-5 py-5 gap-8">
            <div className='flex-1 gap-4 flex flex-col'>
              <label
                htmlFor="videoLink"
                className="text-[#5a7184] font-MontserratArmSemiBold block"
              >{t('Studio Works')}</label>
              <div className='flex flex-wrap gap-4'>
                <WorkPhoto studioWork={studioWorksData?.work1} slug={'1'}/>
                <WorkPhoto studioWork={studioWorksData?.work2} slug={'2'}/>
                <WorkPhoto studioWork={studioWorksData?.work3} slug={'3'}/>
              </div>
            </div>
            <div className="flex-1">
              <div className="flex flex-col mb-6 w-full">
                {!isLoading && !isError && (
                  <>
                    <label
                      htmlFor="videoLink"
                      className="text-[#5a7184] font-MontserratArmSemiBold block"
                    >{t('Video EMBED code')}</label>
                    <textarea
                      id="videoLink"
                      rows='10'
                      {...register('videoLink')}
                      placeholder='Enter video EMBED code example (<iframe width="560" height="315" src="https://www.youtube.com/embed/ ...)'
                      className='placeholder:text-[#959ead] text-dark-hard mt-3 rounded-lg px-5 py-4 font-MontserratArmSemiBold block outline-none border resize-none border-[#c3cad9]'
                    />
                    <button
                      disabled={isLoading}
                      type="button"
                      onClick={handleSubmit(handleVideoPreview)}
                      className="my-4 bg-purple-500 text-white hover:bg-purple-500/90 font-MontserratArmSemiBold rounded-lg px-4 py-2 disabled:cursor-not-allowed disabled:opacity-70"
                    >
                      {t('Preview')}
                    </button>
                    {videoLink && <div className="my-8">
                      <label
                        htmlFor="editor"
                        className="text-[#5a7184] font-MontserratArmSemiBold block"
                      >{t('Preview')}</label>
                      <VideoPlayer videoSource={videoLink.videoLink} title={videoLink.title} />
                    </div>}
                  </>
                )}
              </div>
              <button
                disabled={isLoading}
                type="button"
                onClick={handleSubmit(handleUpdateStudioWorks)}
                className="w-full bg-green-500 text-white hover:bg-green-500/90 font-MontserratArmSemiBold rounded-lg px-4 py-2 disabled:cursor-not-allowed disabled:opacity-70"
              >
                {t('Save')}
              </button>
            </div>
          </section>
          )
      }
    </>
  );
};

export default StudioWorks;
