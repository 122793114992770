import axios from 'axios';

export const getAllReviews = async (searchKeyword = '', page = 1, limit = 10) => {
  try {
    const { data, pageData } = await axios.get(
      `${process.env.REACT_APP_HOST}/api/reviews?searchKeyword=${searchKeyword}&page=${page}&limit=${limit}`
    );
    return { data, pageData };
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const createReview = async ({ newData, token }) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    return await axios.put(`${process.env.REACT_APP_HOST}/api/reviews/create`, newData, config);
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const getSingleReview = async ({ slug }) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_HOST}/api/reviews/${slug}`);
    return { data };
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const deleteReview = async ({ slug, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const { data } = await axios.delete(`${process.env.REACT_APP_HOST}/api/reviews/${slug}`, config);
    return data;
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const updateReview = async ({ updatedData, slug, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await axios.put(`${process.env.REACT_APP_HOST}/api/reviews/${slug}`, updatedData, config);
    return data;
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};
