import axios from 'axios';

export const getAllClients = async (searchKeyword = '', page = 1, limit = 10) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_HOST}/api/clients?searchKeyword=${searchKeyword}&page=${page}&limit=${limit}`
    );
    return { data };
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const createClient = async ({ newData, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    return await axios.put(`${process.env.REACT_APP_HOST}/api/clients/create`, newData, config);
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const getSingleClient = async ({ slug }) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_HOST}/api/clients/${slug}`);
    return { data };
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const deleteClient = async ({ slug, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const { data } = await axios.delete(`${process.env.REACT_APP_HOST}/api/clients/${slug}`, config);
    return data;
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const updateClient = async ({ updatedData, slug, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await axios.put(`${process.env.REACT_APP_HOST}/api/clients/${slug}`, updatedData, config);
    return data;
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};
