import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
export const Nav = ({ children, scrollNav }) => {
  const background = scrollNav ? 'shadow-lg bg-dark-light' : 'shadow-0 bg-dark-light700';
  return (
    <nav
      className={`${background} h-20 flex justify-center items-center text-base sticky top-0 z-900 lg:transition-all lg:duration-500 lg:ease-linear`}
    >
      {children}
    </nav>
  );
};

export const NavbarContainer = ({ children }) => (
  <div className="container mx-auto flex justify-between items-center gap-4 h-full">
    {children}
  </div>
);

export const NavLogo = ({ children, ...rest }) => (
  <Link
    {...rest}
    className="justify-self-start cursor-pointer text-2xl flex items-center ml-0.5 font-bold no-underline h-full py-2"
  >
    {children}
  </Link>
);

export const MobileIcon = ({ children, ...rest }) => (
  <div
    {...rest}
    className="lg:hidden md:hidden block absolute right-4 transform text-3xl cursor-pointer"
  >
    {children}
  </div>
);

export const NavMenu = ({ children, className, direction }) => (
  <ul className={`items-start lg:text-dark-hard lg:flex md:flex hidden ${direction || 'flex-col'} text-sm font-bold ${className}`}>
    {children}
  </ul>
);

export const FooterNavMenu = ({ children, className }) => (
  <ul className={`text-dark-hard text-sm font-bold mt-0 space-y-4 inline-flex items-start flex-col px-4 ${className}`}>
    {children}
  </ul>
);

export const NavItem = ({ children }) => <li className="relative group cursor-pointer px-4">{children}</li>;

export const NavLink = ({ navLinkItem, className, isFooter = false, ...rest }) => {
  const isDropDown = (navLinkItem.type && navLinkItem.type === 'dropdown');
  const [dropdown, setDropdown] = useState(false);
  const { t } = useTranslation();

  const toggleDropdownHandler = () => {
    setDropdown((curState) => {
      return !curState;
    });
  };

  return (
    <>
      {!isDropDown
        ? (
          <>
            <LinkScroll
              to={navLinkItem.href}
              {...rest}
              className={isFooter ? `p-0 ${className}` : `navLink py-2 ${className}`}>
              {t(navLinkItem.name)}
            </LinkScroll>
            <span
              className={'slash cursor-pointer text-primary absolute transition-all duration-500 font-bold right-0 top-0 group-hover:right-[calc(100%-0.5rem)] opacity-0 group-hover:opacity-100'}>
              /
            </span>
          </>
        )
        : (
          <div className="flex flex-col items-center">
            <button
              className="px-4 py-2 flex gap-x-1 items-center"
              onClick={toggleDropdownHandler}
            >
              <span>{navLinkItem.name}</span>
              <MdKeyboardArrowDown/>
            </button>
            <div className={`${
              dropdown ? 'block' : 'hidden'
            } lg:hidden transition-all duration-500 pt-4 lg:absolute lg:bottom-0 lg:right-0 lg:transform lg:translate-y-full lg:group-hover:block w-max`}>
              <ul
                className="bg-dark-soft lg:bg-transparent text-center flex flex-col shadow-lg rounded-lg overflow-hidden">
                {navLinkItem.items.map((page, index) => (
                  <Link
                    key={index}
                    to={page.href}
                    className="hover:bg-dark-hard hover:text-white px-4 py-2 text-white lg:text-dark-hard"
                  >
                    {t(page.title)}
                  </Link>
                ))}
              </ul>
            </div>
          </div>
        )}
    </>
  );
};

export const NavHref = ({ navLinkItem, className, ...rest }) => {
  return (
    <>
      <Link
        to={navLinkItem.href}
        {...rest}
        target='_blank'
        rel='noopener'
        className={`p-0 ${className}`}>
        {navLinkItem.name}
      </Link>
      <span
        className={'slash cursor-pointer text-primary absolute transition-all duration-500 font-bold right-0 top-0 group-hover:right-[calc(100%+10px)]  opacity-0 group-hover:opacity-100'}>
        / </span>
    </>
  );
};

export const NavBtn = ({ children, className, ...rest }) => (
  <button
    {...rest}
    className={className || 'm-0 lg:flex md:flex hidden gap-x-1 items-center border-2 border-primary px-6 py-2 rounded-full text-primary font-MontserratArmSemiBold hover:bg-primary hover:text-white transition-all duration-300'}>
    {children}
  </button>
);

export const NavBtnLink = ({ children, classname, ...rest }) => (
  <Link {...rest} className={classname}>
    {children}
  </Link>
);
