import React from 'react';

const SocialMediaInputs = ({ register, t }) => {
  return (
    <>
      <h2 className="text-xl font-MontserratArmSemiBold mb-4">{t('Social Media Links')}</h2>
      <div className='w-full flex md:flex-row flex-col flex-wrap  flex-1 gap-4 my-4'>
        <div className="flex flex-col md:w-[calc(50%-0.5rem)] w-full">
          <label htmlFor="facebook" className='text-[#5a7184] font-MontserratArmSemiBold block'>Facebook</label>
          <input
            type="text"
            id="facebook"
            name="socialLinks.facebook"
            {...register('socialLinks.facebook')}
            className='placeholder:text-[#959ead] text-dark-hard mt-3 rounded-lg px-5 py-4 font-MontserratArmSemiBold block outline-none border border-[#c3cad9]'
          />
        </div>
        <div className="flex flex-col md:w-[calc(50%-0.5rem)] w-full">
          <label htmlFor="twitter" className='text-[#5a7184] font-MontserratArmSemiBold block'>Twitter</label>
          <input
            type="text"
            id="twitter"
            name="socialLinks.twitter"
            {...register('socialLinks.twitter')}
            className='placeholder:text-[#959ead] text-dark-hard mt-3 rounded-lg px-5 py-4 font-MontserratArmSemiBold block outline-none border border-[#c3cad9]'
          />
        </div>
        <div className="flex flex-col md:w-[calc(50%-0.5rem)] w-full">
          <label htmlFor="instagram" className='text-[#5a7184] font-MontserratArmSemiBold block'>Instagram</label>
          <input
            type="text"
            id="instagram"
            name="socialLinks.instagram"
            {...register('socialLinks.instagram')}
            className='placeholder:text-[#959ead] text-dark-hard mt-3 rounded-lg px-5 py-4 font-MontserratArmSemiBold block outline-none border border-[#c3cad9]'
          />
        </div>
        <div className="flex flex-col md:w-[calc(50%-0.5rem)] w-full">
          <label htmlFor="youTube" className='text-[#5a7184] font-MontserratArmSemiBold block'>YouTube</label>
          <input
            type="text"
            id="youTube"
            name="socialLinks.youtube"
            {...register('socialLinks.youtube')}
            className='placeholder:text-[#959ead] text-dark-hard mt-3 rounded-lg px-5 py-4 font-MontserratArmSemiBold block outline-none border border-[#c3cad9]'
          />
        </div>
        {/* Add more social media input fields as needed */}
      </div>
    </>
  );
};

export default SocialMediaInputs;
