import Logo from '../assets/logo.png';
import HeroImage from '../assets/heroImage.svg';
import HeroImage1 from '../assets/demo1.jpg';
import HeroImage2 from '../assets/demo2.webp';
import HeroImage3 from '../assets/demo3.jpg';
const noImage = '/images/noImage.jpg';
const userImage = '/images/user.png';

const images = {
  Logo,
  HeroImage,
  HeroImage1,
  HeroImage2,
  HeroImage3,
  noImage,
  userImage
};

export default images;
