import React, { useEffect, useState } from 'react';
import { images, stables } from '../../../../constants';
import Pagination from '../../../../components/Pagination';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { deleteReview, getAllReviews } from '../../../../services/index/reviews';
import ErrorMessage from '../../../../components/ErrorMessage';
import { useTranslation } from 'react-i18next';

const ManageReview = () => {
  const userState = useSelector((state) => state.user);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);
  const [reviewsData, setReviewsData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchReviews(searchKeyword, currentPage);
  }, [searchKeyword, currentPage]);
  const fetchReviews = async (searchKeyword, currentPage) => {
    try {
      const {
        data,
        isLoading,
        isFetching,
        isError
      } = await getAllReviews(searchKeyword, currentPage);
      setReviewsData({
        data: data.result,
        pageData: data.pageData
      });
      setIsLoading(isLoading);
      setIsFetching(isFetching);
      setIsError(isError);
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  const handleDeleteReview = async ({ slug, token }) => {
    if(window.confirm('Do you want to delete this Review?')) {
      try {
        await deleteReview({ slug, token });
        setIsLoading(true);
        return fetchReviews('', 1).then((r) => r || toast.success('Review is deleted'));
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    }
  };

  const searchKeywordHandler = (e) => {
    const { value } = e.target;
    setSearchKeyword(value);
  };

  const submitSearchKeywordHandler = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchReviews(searchKeyword, 1);
  };

  return (
    <div>
      <h1 className="text-xl font-MontserratArmSemiBold">{t('Mange Reviews')}</h1>

      <div className="w-full px-4 mx-auto">
        <div className="py-8">
          <div className="flex flex-row justify-between w-full mb-1 sm:mb-0">
            <h2 className="text-xl leading-tight">{t('Reviews')}</h2>
            <div className="text-end">
              <form
                onSubmit={submitSearchKeywordHandler}
                className="flex flex-col justify-center w-3/4 max-w-sm space-y-3 md:flex-row md:w-full md:space-x-3 md:space-y-0"
              >
                <div className=" relative ">
                  <input
                    type="text"
                    id='"form-subscribe-Filter'
                    className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    placeholder="Review title..."
                    onChange={searchKeywordHandler}
                    value={searchKeyword}
                  />
                </div>
                <button
                  className="flex-shrink-0 px-4 py-2 text-base font-MontserratArmSemiBold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
                  type="submit"
                >
                  {t('Filter')}
                </button>
              </form>
            </div>
            <div className="text-end">
              <button
                className="flex-shrink-0 px-4 py-2 text-base font-MontserratArmSemiBold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
                onClick={() => {
                  navigate('/admin/reviews/new');
                }}>
                {t('Add Review')}
              </button>
            </div>
          </div>
          <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
            <div className="inline-block min-w-full overflow-hidden rounded-lg shadow">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200"
                    >
                      {t('Title')}
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200"
                    >
                      {t('Created at')}
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading || isFetching
                    ? (
                      <tr>
                        <td colSpan={5} className="text-center py-10 w-full">
                          Loading...
                        </td>
                      </tr>)
                    : isError
                      ? (
                        <ErrorMessage message={"Couldn't fetch the reviews data"} />
                      )
                      : (reviewsData?.data?.length === 0
                        ? (
                          <tr>
                            <td colSpan={5} className="text-center py-10 w-full">
                              {t('No reviews found')}
                            </td>
                          </tr>
                        )
                        : (
                          reviewsData?.data?.map((review, key) => (
                            <tr key={key.toString()}>
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <div className="flex items-center">
                                  <div className="flex-shrink-0">
                                    <a href="/" className="relative block">
                                      <img
                                        src={
                                          review?.photo
                                            ? stables.UPLOAD_FOLDER_BASE_URL +
                                        review?.photo
                                            : images.noImage
                                        }
                                        alt={reviewsData.data[key].titles?.en}
                                        className="mx-auto object-cover rounded-lg w-10 aspect-square"
                                      />
                                    </a>
                                  </div>
                                  <div className="ml-3">
                                    <p className="text-gray-900 whitespace-no-wrap">
                                      {reviewsData.data[key].titles?.en}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {new Date(review.createdAt).toLocaleDateString(
                                    'en-US',
                                    {
                                      day: 'numeric',
                                      month: 'short',
                                      year: 'numeric'
                                    }
                                  )}
                                </p>
                              </td>
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 space-x-5">
                                <button
                                  disabled={isLoading}
                                  type="button"
                                  className="text-red-600 hover:text-red-900 disabled:opacity-70 disabled:cursor-not-allowed"
                                  onClick={() => {
                                    handleDeleteReview({
                                      slug: review?.slug,
                                      token: userState.userInfo.token
                                    });
                                  }}
                                >
                                  Delete
                                </button>
                                <Link
                                  to={`/admin/reviews/manage/edit/${review?.slug}`}
                                  className="text-green-600 hover:text-green-900"
                                >
                                  Edit
                                </Link>
                              </td>
                            </tr>
                          )
                          )))}
                </tbody>
              </table>
              {!isLoading && reviewsData?.data && (
                <Pagination
                  onPageChange={(page) => setCurrentPage(page)}
                  currentPage={currentPage}
                  totalPageCount={JSON.parse(
                    reviewsData?.pageData?.totalPageCount
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageReview;
