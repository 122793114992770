import React, { useEffect, useRef, useState } from 'react';
import { images, stables } from '../../../constants';
import VideoPlayer from '../../../video/VideoPlayer';
import { getStudioWorksData } from '../../../services/index/studioWroks';
import { toast } from 'react-hot-toast';
import { motion, useInView, useTransform, useScroll } from 'framer-motion';
import useDimension from '../../../hooks/useDimension';
import ArticleDetailSkeleton from '../../articleDetail/components/ArticleDetailSkeleton';
import ErrorMessage from '../../../components/ErrorMessage';

const getRelativeCoordinates = (e, referenceElement, childElement) => {
  const position = {
    x: e.pageX,
    y: e.pageY
  };

  const offset = {
    left: referenceElement.offsetLeft,
    top: referenceElement.offsetTop,
    width: referenceElement.clientWidth,
    height: referenceElement.clientHeight
  };

  let reference = referenceElement.offsetParent;

  while(reference) {
    offset.left += reference.offsetLeft;
    offset.top += reference.offsetTop;
    reference = reference.offsetParent;
  }

  return {
    x: position.x - offset.left - (childElement.clientWidth / 2),
    y: position.y - offset.top - (childElement.clientHeight / 2),
    width: offset.width,
    height: offset.height,
    centerX: (position.x - offset.left - offset.width / 2) / (offset.width / 2),
    centerY: (position.y - offset.top - offset.height / 2) / (offset.height / 2)
  };
};

const Hero = () => {
  const sectionRef = useRef(null);
  const container = useRef(null);
  const blurElement = useRef(null);
  const logoContainer = useRef(null);
  const { width } = useDimension();
  const [studioWorksData, setStudioWorksData] = useState(null);
  const isVisible = useInView(sectionRef);
  const [mousePosition, setMousePosition] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start end', 'end start']
  });
  const y1 = useTransform(scrollYProgress, [0, 1], [-100, 280]);
  const y2 = useTransform(scrollYProgress, [0, 1], [-30, 80]);

  useEffect(() => {
    const fetchStudioWorks = async () => {
      try {
        const { data, isLoading, isError } = await getStudioWorksData();
        setStudioWorksData(data);
        setIsLoading(isLoading);
        setIsError(isError);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };
    // Fetch studioWorks when the component mounts
    fetchStudioWorks();

    const element = logoContainer.current;
    // Attach mousemove event listener to the parent div
    element?.addEventListener('mousemove', handleMouseMove);

    // Clean up the event listener when the component unmounts
    return () => {
      element?.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  const handleMouseMove = (e) => {
    setMousePosition(getRelativeCoordinates(e, logoContainer.current, blurElement.current));
  };

  return (
    <section id='hero' ref={sectionRef} className={`w-full md:-mt-20 -mt-8 ransition-opacity ease-in duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`} >
      {isLoading
        ? (
          <ArticleDetailSkeleton />
        )
        : isError
          ? (
            <ErrorMessage message="Couldn't fetch the Works section" />
          )
          : (<>
            <motion.div
              ref={logoContainer}
              style={{ perspective: 600 }}
              onMouseMove={handleMouseMove}
              onTouchMove={e => handleMouseMove(e.targetTouches[0])}
              animate={{
                rotateX: mousePosition.centerX * 20,
                rotateY: mousePosition.centerY * 20
              }}
              className="overflow-hidden w-full h-[200px] md:h-[400px] flex justify-center items-center relative">
              <img src={images.HeroImage} alt=""/>
              <motion.div
                ref={blurElement}
                animate={{
                  x: mousePosition.x,
                  y: mousePosition.y
                }}
                transition={{
                  type: 'tween',
                  duration: 0.5
                }}
                style={{ mask: 'radial-gradient(rgb(2 0 36) 24%, rgb(255 255 255 / 0%) 68%, rgb(255 255 255 / 0%) 100%)' }}
                className="radial-gradient-mask absolute md:w-[25vw] w-[35vw] md:h-[25vw] h-[35vw] top-0 left-0 rounded-full backdrop-blur-[4px]"/>
            </motion.div>
            <div ref={container} className="magic inset-0 w-full md:mb-0 my-12 flex md:flex-row flex-col">

              {studioWorksData && [...Array(3)].map((item, i) => (

                <motion.div key={i.toString()} style={{ y: width > 768 ? (i === 1 ? y1 : y2) : 0 }} className={`lg:w-1/3 w-full md:m-0 mb-12
            relative flex justify-center px-4 grayscale hover:grayscale-0 ${i === 1 ? 'md:top-[-150px]' : 'md:top-[0px]'}`}>
                  <img className="w-full h-auto lg:max-w-[250px] max-w-[460px] object-cover aspect-[2/3]"
                    src={studioWorksData[`work${i + 1}`] ? stables.UPLOAD_FOLDER_BASE_URL + studioWorksData[`work${i + 1}`] : images.noImage}
                    alt="Studio work photos"/>
                </motion.div>

              ))}
            </div>
            {
              studioWorksData && <div className="bg-gray-100 h-full py-8">
                <VideoPlayer videoSource={studioWorksData.videoLink} title={studioWorksData.title}/>
              </div>
            }
          </>)
      }
    </section>
  );
};

export default Hero;
