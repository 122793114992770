import React from 'react';
import { NavLink } from 'react-router-dom';

export const NavItem = ({
  link,
  title,
  icon,
  name,
  activeNavName,
  setActiveNavName
}) => {
  return (
    <NavLink
      to={link}
      className={`${
        name === activeNavName
          ? 'font-bold text-primary'
          : 'font-MontserratArmSemiBold text-[#A5A5A5]'
      } flex items-center gap-x-2 py-2 text-lg`}
      onClick={() => setActiveNavName(name)}
    >
      {icon}
      {title}
    </NavLink>
  );
};

export const NavButton = ({
  link,
  title,
  icon,
  name,
  activeNavName,
  onClick
}) => {
  return (
    <span
      className={`${
        name === activeNavName
          ? 'font-bold text-primary'
          : 'font-MontserratArmSemiBold text-[#A5A5A5]'
      } flex items-center gap-x-2 py-2 text-lg cursor-pointer`}
      onClick={onClick}
    >
      {icon}
      {title}
    </span>
  );
};
