import axios from 'axios';

export const getAllTranslations = async () => {
  try {
    const { data, headers } = await axios.get(`${process.env.REACT_APP_HOST}/api/translations`);
    return { data, headers };
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const updateAllTranslations = async ({ updatedData, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await axios.put(`${process.env.REACT_APP_HOST}/api/translations`, updatedData, config);
    return data;
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};
