import React, { useEffect, useState } from 'react';
import ArticleDetailSkeleton from '../../../articleDetail/components/ArticleDetailSkeleton';
import ErrorMessage from '../../../../components/ErrorMessage';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useForm } from 'react-hook-form';
import { NavBtn } from '../../../../components/navBar/NavbarElements';
import { locales } from '../../../../components/PageData';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { getAboutUsData, updateAboutUsData } from '../../../../services/index/about';

const AboutUs = () => {
  const userState = useSelector((state) => state.user);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [aboutUsData, setAboutUsData] = useState(null);
  const [currentLng, setCurrentLng] = useState(Object.keys(locales)[0]);
  const { t, i18n } = useTranslation();
  const [editor, setEditor] = useState(EditorState.createEmpty());

  useEffect(() => {
    // Fetch aboutUss when the component mounts
    fetchAboutUs();
  }, []);
  const fetchAboutUs = async () => {
    try {
      const { data, isLoading, isError } = await getAboutUsData();
      setAboutUsData(data);
      setIsLoading(isLoading);
      setIsError(isError);
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };
  const changeLanguage = (locale) => {
    setCurrentLng(locale);
  };
  const saveState = () => {
    if(aboutUsData && aboutUsData.bodies && aboutUsData.bodies[currentLng]) {
      const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(aboutUsData.bodies[currentLng])));
      setEditor(editorState);
    }
  };

  const { handleSubmit } = useForm({ mode: 'onChange' });

  useEffect(() => {
    setCurrentLng(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if(!isLoading && !isError && aboutUsData.bodies[currentLng] && JSON.parse(aboutUsData?.bodies[currentLng]).blocks) {
      const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(aboutUsData.bodies[currentLng])));
      setEditor(editorState);
    } else {
      setEditor(EditorState.createEmpty());
    }
  }, [aboutUsData?.bodies, currentLng, isError, isLoading]);

  const handleUpdateAboutUs = async () => {
    const content = JSON.stringify(convertToRaw(editor.getCurrentContent()));
    const arr = { ...aboutUsData, bodies: { ...aboutUsData.bodies, [currentLng]: content } };
    try {
      await updateAboutUsData({ updatedData: arr, token: userState.userInfo.token });

      return fetchAboutUs().then((r) => r || toast.success('AboutUs is updated'));
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };
  const onEditorStateChange = (editorState) => {
    setEditor(editorState);
  };

  return (
    <>
      {isLoading
        ? (
          <ArticleDetailSkeleton />
        )
        : isError
          ? (
            <ErrorMessage message="Couldn't fetch the aboutUs detail" />
          )
          : (<section className="container mx-auto max-w-5xl flex flex-col px-5 py-5 lg:flex-row lg:gap-x-5 lg:items-start">
            <article className="flex-1">
              <div className='flex justify-end w-full my-4'>
                <div
                  className="text-white items-center gap-y-5 lg:text-dark-hard flex flex-col lg:flex-row gap-x-2 font-MontserratArmSemiBold">
                  <div className="relative group">
                    <div className="flex flex-col items-center">
                      <NavBtn onClick={() => setProfileDropdown(!profileDropdown)}>
                        <span>{locales[currentLng].name}</span>
                        <MdKeyboardArrowDown/>
                      </NavBtn>
                      <div
                        className={`${
                          profileDropdown ? 'block' : 'hidden'
                        } lg:hidden z-900 transition-all duration-500 pt-4 absolute bottom-0 right-0 transform translate-y-full group-hover:block w-max`}
                      >
                        <ul
                          className="bg-dark-light text-center flex flex-col shadow-lg rounded-lg overflow-hidden w-full">
                          {Object.keys(locales).map((locale) => (
                            <NavBtn
                              key={locale}
                              onClick={() => changeLanguage(locale)}
                              className={`hover:bg-dark-hard hover:text-white px-4 py-2 text-dark-hard text-center cursor-pointer uppercase group ${currentLng === locale ? 'font-bold' : 'font-normal'}`}>
                              <p>{locales[locale].name}</p>
                            </NavBtn>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col mb-6 w-full">
                {!isLoading && !isError && (
                  <>
                    <label
                      htmlFor="editor"
                      onClick={saveState}
                      className="text-[#5a7184] font-MontserratArmSemiBold block"
                    >Description</label>
                    <Editor
                      editorState={editor}
                      editorClassName="bg-white text-dark-hard mt-3 rounded-lg px-5 py-4 block outline-none border border-slate-300"
                      onEditorStateChange={onEditorStateChange}
                    />
                  </>
                )}
              </div>
              <button
                disabled={isLoading}
                type="button"
                onClick={handleSubmit(handleUpdateAboutUs)}
                className="w-full bg-green-500 text-white hover:bg-green-500/90 font-MontserratArmSemiBold rounded-lg px-4 py-2 disabled:cursor-not-allowed disabled:opacity-70"
              >
                {t('Save')}
              </button>
            </article>
          </section>
          )
      }
    </>
  );
};

export default AboutUs;
