import React, { useEffect, useRef, useState } from 'react';
import ReviewCard from './ReviewCard';
import Slider from 'react-slick';
import { getAllReviews } from '../../services/index/reviews';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../ErrorMessage';
import { motion, useInView, useScroll, useTransform } from 'framer-motion';

const Reviews = () => {
  const sectionRef = useRef(null);
  const backgroundRef = useRef(null);
  const [reviewList, setReviewList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();
  const isVisible = useInView(sectionRef);
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ['start end', 'end start']
  });
  const y1 = useTransform(scrollYProgress, [0, 0.6], [0, 280]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const { data, isLoading, isError } = await getAllReviews();
        setReviewList((prevReviews) => [...prevReviews, ...data.result]);
        setIsLoading(isLoading);
        setIsError(isError);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };

    fetchReviews();
  }, []);

  const settings = {
    className: `reviews duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`,
    centerMode: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    speed: 500,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <section
      id='reviews'
      ref={sectionRef}
      className='relative overflow-hidden inline-block w-full mx-auto px-5 pb-12 border-b-2'>
      <motion.div
        ref={backgroundRef}
        style={{ y: y1, transitionDuration: '100ms' }}
        className='flex justify-center items-center text-dark-soft font-MontserratArmSemiBold top-0 left-0 w-full h-auto absolute text-[23vw] leading-[11rem] uppercase'>
        {t('Review')}
      </motion.div>
      <motion.div
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ amount: 0.1 }}
        initial={{ opacity: 0, x: -100 }}
        transition={{ duration: 0.7 }} >
        <h3 className="section-title element-left">{t('Reviews')}</h3>
      </motion.div>
      {isLoading
        ? (
          <div className="text-center py-10 w-full">
            Loading...
          </div>
        )
        : isError
          ? (
            <ErrorMessage message={"Couldn't fetch the reviews data"} />
          )
          : (reviewList &&
            <Slider {...{ ...settings, slidesToShow: reviewList.length > 2 ? 3 : reviewList.length } }>
              {reviewList.map((review, key) => (
                <ReviewCard key={key.toString()} review={review}/>
              ))}
            </Slider>
          )}
    </section>
  );
};

export default Reviews;
