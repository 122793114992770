import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ArticleDetailSkeleton from '../../../articleDetail/components/ArticleDetailSkeleton';
import ErrorMessage from '../../../../components/ErrorMessage';
import { images, stables } from '../../../../constants';
import { HiOutlineCamera } from 'react-icons/hi';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getSingleReview, updateReview } from '../../../../services/index/reviews';
import { NavBtn } from '../../../../components/navBar/NavbarElements';
import { locales } from '../../../../components/PageData';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';

const ImageUpload = ({ photo, initialPhoto, handleFileChange, alt, handleDeleteImage }) => {
  return (
    <label htmlFor="reviewPicture" className="w-full cursor-pointer">
      {photo
        ? (
          <img src={URL.createObjectURL(photo)} alt={alt} className="rounded-xl w-full max-w-[220px] object-contain"/>
        )
        : initialPhoto
          ? (
            <img src={initialPhoto} alt={alt} className="rounded-xl w-full max-w-[220px] object-contain"/>
          )
          : (
            <div className="w-full min-h-[200px] bg-blue-50/50 flex justify-center items-center">
              <HiOutlineCamera className="w-7 h-auto text-primary"/>
            </div>
          )}
      <input
        type="file"
        className="sr-only"
        id="reviewPicture"
        onChange={handleFileChange}
      />
      <button
        type="button"
        onClick={handleDeleteImage}
        className="w-fit bg-red-500 text-sm text-white font-MontserratArmSemiBold rounded-lg px-2 py-1 mt-5"
      >
        Delete Image
      </button>
    </label>
  );
};

const EditReview = () => {
  const { slug } = useParams();
  const userState = useSelector((state) => state.user);
  const [initialPhoto, setInitialPhoto] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isImageRemoved, setIsImageRemoved] = useState(false);
  const [reviewData, setReviewData] = useState(null);
  const [currentLng, setCurrentLng] = useState(Object.keys(locales)[0]);
  const [formData, setFormData] = useState({});

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const { data, isLoading, isError } = await getSingleReview({ slug });
        setReviewData(data);
        setFormData({ titles: data.titles, captions: data.captions });
        setIsLoading(isLoading);
        setIsError(isError);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };
    fetchReviews();
  }, [slug]);

  useEffect(() => {
    setCurrentLng(i18n.language);
  }, [i18n.language]);

  const changeLanguage = async (data) => {
    const { title, caption, locale } = data;
    setFormData({ titles: { ...formData.titles, [currentLng]: title }, captions: { ...formData.captions, [currentLng]: caption } });
    setCurrentLng(locale);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    if(reviewData) {
      setInitialPhoto(reviewData?.photo
        ? stables.UPLOAD_FOLDER_BASE_URL +
        reviewData?.photo
        : images.noImage);
      reviewData.titles &&
      reset({ title: formData.titles[currentLng] || reviewData.titles[currentLng], caption: formData.captions[currentLng] || reviewData?.captions[currentLng] });
    }
  }, [reset, reviewData, currentLng, formData]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPhoto(file);
  };

  const handleUpdateReview = async (data) => {
    const updatedData = new FormData();

    // Check if there's a new photo, if so, append it
    if(photo) {
      updatedData.append('reviewPicture', photo);
    }
    const { title, caption } = data;
    const arr = { titles: { ...formData.titles, [currentLng]: title }, captions: { ...formData.captions, [currentLng]: caption }, isImageRemoved: photo === null && initialPhoto === null && isImageRemoved };

    updatedData.append('document', JSON.stringify({ data: arr }));

    try {
      await updateReview({ updatedData, slug, token: userState.userInfo.token });

      return toast.success('Review is updated');
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  const handleDeleteImage = () => {
    if(window.confirm('Do you want to delete your Review picture?')) {
      setInitialPhoto(null);
      setPhoto(null);
      setIsImageRemoved(true);
    }
  };

  return (
    <>
      {isLoading
        ? (
          <ArticleDetailSkeleton />
        )
        : isError
          ? (
            <ErrorMessage message="Couldn't fetch the review detail" />
          )
          : (<section className="container mx-auto max-w-5xl flex flex-col px-5 py-5 lg:flex-row lg:gap-x-5 lg:items-start">
            <article className="flex-1">
              <ImageUpload
                photo={photo}
                initialPhoto={initialPhoto}
                alt={reviewData?.title}
                handleFileChange={handleFileChange}
                handleDeleteImage={handleDeleteImage}/>
              <div className='flex justify-end w-full my-4'>
                <div
                  className="text-white items-center gap-y-5 lg:text-dark-hard flex flex-col lg:flex-row gap-x-2 font-MontserratArmSemiBold">
                  <div className="relative group">
                    <div className="flex flex-col items-center">
                      <NavBtn onClick={() => setProfileDropdown(!profileDropdown)}>
                        <span>{locales[currentLng].name}</span>
                        <MdKeyboardArrowDown/>
                      </NavBtn>
                      <div
                        className={`${
                          profileDropdown ? 'block' : 'hidden'
                        } lg:hidden transition-all duration-500 pt-4 absolute bottom-0 right-0 transform translate-y-full group-hover:block w-max`}
                      >
                        <ul
                          className="bg-dark-light text-center flex flex-col shadow-lg rounded-lg overflow-hidden w-full">
                          {Object.keys(locales).map((locale) => (
                            <NavBtn
                              key={locale}
                              onClick={handleSubmit((data) => changeLanguage({ ...data, locale }))}
                              className={`hover:bg-dark-hard hover:text-white px-4 py-2 text-dark-hard text-center cursor-pointer uppercase group ${currentLng === locale ? 'font-bold' : 'font-normal'}`}>
                              <p>{locales[locale].name}</p>
                            </NavBtn>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col mb-6 w-full">
                <label
                  htmlFor="title"
                  className="text-[#5a7184] font-MontserratArmSemiBold block"
                >
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  {...register('title', {
                    required: {
                      value: false,
                      message: 'Title is required'
                    }
                  })}
                  placeholder="Enter Caption"
                  className={`placeholder:text-[#959ead] text-dark-hard mt-3 rounded-lg px-5 py-4 font-MontserratArmSemiBold block outline-none border ${
                    errors.title ? 'border-red-500' : 'border-[#c3cad9]'
                  }`}
                />
                {errors.title?.message && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.title?.message}
                  </p>
                )}
              </div>
              <div className="flex flex-col mb-6 w-full">
                <label
                  htmlFor="caption"
                  className="text-[#5a7184] font-MontserratArmSemiBold block"
                >
                  Caption
                </label>
                <textarea
                  id="caption"
                  rows='10'
                  {...register('caption', {
                    required: {
                      value: false,
                      message: 'Caption is required'
                    }
                  })}
                  placeholder="Enter Caption"
                  className={`placeholder:text-[#959ead] text-dark-hard mt-3 rounded-lg px-5 py-4 font-MontserratArmSemiBold block outline-none border resize-none ${
                    errors.caption ? 'border-red-500' : 'border-[#c3cad9]'
                  }`}
                />
                {errors.caption?.message && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.caption?.message}
                  </p>
                )}
              </div>
              <button
                disabled={isLoading}
                type="button"
                onClick={handleSubmit(handleUpdateReview)}
                className="w-full bg-green-500 text-white hover:bg-green-500/90 font-MontserratArmSemiBold rounded-lg px-4 py-2 disabled:cursor-not-allowed disabled:opacity-70"
              >
                {t('Save')}
              </button>
            </article>
          </section>
          )
      }
    </>
  );
};

export default EditReview;
