import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import ErrorMessage from '../../../components/ErrorMessage';
import { getAllTranslations, updateAllTranslations } from '../../../services/index/translations';
import { locales } from '../../../components/PageData';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Translations = () => {
  const inputRef = useRef();
  const userState = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [translations, setTranslations] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    // Fetch clients when the component mounts
    fetchTranslations();
  }, []);

  const allKeys = Object.keys(translations).reduce((result, lang) => {
    const langKeys = Object.keys(translations[lang].value);
    langKeys.forEach((key) => {
      if(!result.includes(key)) {
        result.push(key);
      }
    });
    return result;
  }, []);

  const fetchTranslations = async () => {
    try {
      const { data, isLoading, isError } = await getAllTranslations();
      setIsLoading(isLoading);
      setIsError(isError);
      setTranslations(data);
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  // Function to handle changes in translation values
  const handleValueChange = ({ e, key, lang, value }) => {
    // Check if the new value is different from the placeholder value
    const OriginalValue = translations.find(
      (t) => t.lang === lang
    )?.value[key];

    if(value !== OriginalValue) {
      setIsDirty(true);
      e.target.classList.add('border-purple-600');
    } else {
      setIsDirty(false);
      e.target.classList.remove('border-purple-600');
    }
  };
  // Function to save changes to the backend
  const saveTranslations = async (data) => {
    try {
      const updatedData = {};

      for(const translation of translations) {
        const lang = translation.lang;
        const value = translation.value;

        for(const key of allKeys) {
          const editedTranslation = data.translations[lang][key];
          const originalTranslation = value[key];
          if(editedTranslation !== originalTranslation) {
            if(!updatedData[lang]) {
              updatedData[lang] = {};
            }
            if(!updatedData[lang][key]) {
              updatedData[lang][key] = editedTranslation;
            }
          }
        }
      }
      if(updatedData) {
        setIsLoading(true);
        return await updateAllTranslations({ updatedData, token: userState.userInfo.token }).then(() => {
          setIsDirty(false);
          setIsLoading(false);
          toast.success('Translations saved successfully');
        });
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
      console.error(error);
    }
  };

  return (
    <div>
      <div className='flex md:flex-row flex-col justify-between items-center gap-4'>
        <h1 className="text-xl font-MontserratArmSemiBold">{t('Translations')}</h1>
        <button
          className='ml-auto disabled:opacity-50 flex-shrink-0 px-4 py-2 text-base font-MontserratArmSemiBold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200'
          disabled={!isDirty}
          onClick={handleSubmit(saveTranslations)}>{t('Save')}
        </button>
      </div>

      <div className="overflow-x-auto max-h-[80vh] my-8">
        {isLoading
          ? (
            <div className="text-center py-10 w-full">
              Loading...
            </div>)
          : isError
            ? (
              <ErrorMessage message={'Could not fetch the translation data'} />
            )
            : (<table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-4 min-w-[320px]">{t('Keys')}</th>
                  {translations.map(({ lang }) => (
                    <th key={lang} className="px-4 min-w-[320px]">
                      {locales[lang].name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {allKeys.sort((a, b) => a[0].localeCompare(b[0])).map((key, n) => (
                  <tr key={key}>
                    <td className="px-4 py-2">{key}</td>
                    {translations.map(({ lang, value }, index) => (
                      <td key={lang} className="px-4 min-w-[320px]">
                        <input
                          ref={inputRef}
                          type="text"
                          id={`translation_${n}_${index}`}
                          {...register(`translations.${lang}.${key}`, {
                            required: {
                              value: false
                            }
                          })}
                          onChange={(e) => handleValueChange({ e, key, lang, value: e.target.value })}
                          placeholder={value[key]}
                          defaultValue={value[key]}
                          className={`placeholder:text-[#959ead] text-dark-hard mt-3 rounded-lg px-5 py-4 font-MontserratArmSemiBold block outline-none border ${
                            errors.title ? 'border-red-500' : 'border-[#c3cad9]'
                          }`}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>)
        }
      </div>
    </div>
  );
};

export default Translations;
