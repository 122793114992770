import React from 'react';

import { images, stables } from '../../constants';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const ClientCard = ({ post, index }) => {
  const { i18n } = useTranslation();
  return (
    <div className='flex w-full border-b-2 md:py-12 pb-8 pt-4'>
      <motion.div
        className={'w-1/3 md:block hidden'}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ amount: 0.1 }}
        initial={{ opacity: 0, x: -100 }}
        transition={{ duration: 0.5 }} >
        <span className='element-left font-bold text-primary text-xl md:text-xl lg:text-2xl lg:min-w-[82px] min-w-[32px] text-right'>{index}</span>
      </motion.div>
      <motion.div
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ amount: 0.5 }}
        initial={{ opacity: 0, y: '-20%' }}
        transition={{ duration: 0.8 }}
        className='w-full flex justify-between md:items-start items-center' >
        <div className='w-full flex md:flex-row sm:flex-col flex-col justify-evenly lg:text-start items-start lg:p-0 gap-2'>
          <h2 className="text-xl text-dark-hard font-bold md:text-xl lg:text-2xl whitespace-nowrap pr-4">
            {post.titles[i18n.language] || post.title}
          </h2>
          <p className="text-dark-hard lg:w-1/2 w-full pr-4">{post.captions[i18n.language] || post.caption}</p>
        </div>
        <motion.div
          className={'w-1/3'}
          whileInView={{ opacity: 1 }}
          viewport={{ amount: 0.1 }}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.8 }} >
          <img
            src={
              post.photo
                ? stables.UPLOAD_FOLDER_BASE_URL + post.photo
                : images.noImage
            }
            alt="title"
            className="object-contain aspect-square md:w-[35vh] w-[20vh]"
          />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ClientCard;
