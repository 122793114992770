import React, { useEffect, useRef, useState } from 'react';

import ErrorMessage from '../ErrorMessage';
import ClientCard from './ClientCard';
import ClientCardSkeleton from './ClientCardSkeleton';
import { getAllClients } from '../../services/index/clients';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { motion, useInView } from 'framer-motion';
import useDimension from '../../hooks/useDimension';

const Clients = () => {
  const sectionRef = useRef();
  const [clientList, setClientList] = useState([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;
  const [isLoading, setIsLoading] = useState(true);
  const [canShowMore, setCanShowMore] = useState(true);
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();
  const isVisible = useInView(sectionRef);
  const { width } = useDimension();

  useEffect(() => {
    const fetchClients = async (currentPage) => {
      try {
        const { data, isLoading, isError } = await getAllClients('', currentPage, itemsPerPage); // Fetch 5 articles at a time
        setClientList((prevClients) => [...prevClients, ...data.result]);
        setIsLoading(isLoading);
        setCanShowMore(currentPage < parseInt(data.pageData.totalPageCount));
        setIsError(isError);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };

    fetchClients(page);
  }, [page]);

  const loadMore = () => {
    setPage(page + 1);
  };

  return (
    <section id='clients' ref={sectionRef} className={`flex flex-col mx-auto px-5 py-10 gap-8 ransition-opacity ease-in duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
      <motion.div
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ amount: 0.1 }}
        initial={{ opacity: 0, x: -100 }}
        transition={{ duration: 0.7 }}>
        <h3 className={`section-title ${width < 768 && 'element-left'}`}>{t('Our Clients')}</h3>
      </motion.div>
      <div className="flex flex-wrap md:gap-x-5 gap-y-5 pb-0">
        {isLoading
          ? (
            [...Array(3)].map((item, index) => (
              <ClientCardSkeleton
                key={index}
                className="w-full"
              />
            ))
          )
          : isError
            ? (
              <ErrorMessage message={"Couldn't fetch the clients data"} />
            )
            : (
              clientList?.map((post, key) => (
                <ClientCard
                  key={key.toString()}
                  post={post}
                  index={key + 1}
                />
              ))
            )}
      </div>
      {canShowMore && <button className="relative element-right flex items-center justify-end gap-x-2 text-dark-hard p-0 mx-5" onClick={loadMore}>
        <span className='uppercase text-xl'>{t('See More')}</span>
      </button>}
      {/* <p className="relative font-bold flex items-center justify-end lg:text-2xl text-base mx-5 element-right"> */}
    </section>
  );
};
export default Clients;
