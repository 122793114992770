import axios from 'axios';

export const getStudioWorksData = async () => {
  try {
    const { data, headers } = await axios.get(`${process.env.REACT_APP_HOST}/api/studio-works`);
    return { data, headers };
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const updateStudioWorksData = async ({ updatedData, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await axios.put(`${process.env.REACT_APP_HOST}/api/studio-works`, updatedData, config);
    return data;
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const updateStudioWorkPicture = async ({ token, slug, formData }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_HOST}/api/studio-works/updateStudioWorkPicture/${slug}`,
      formData,
      config
    );
    return data;
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};
