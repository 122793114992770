import React from 'react';
import { BsStarFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { images, stables } from '../../constants';

const ReviewCard = ({ review, className }) => {
  const { i18n } = useTranslation();

  return (
    <div className={ `group w-full md:m-0 mb-12 relative flex flex-col items-center px-4 ${className}` }>
      <img className="w-full h-auto max-w-[250px] object-contain aspect-square transition-all duration-500 grayscale group-hover:grayscale-0"
        src={
          review.photo
            ? stables.UPLOAD_FOLDER_BASE_URL + review.photo
            : images.noImage}
        alt={review.title}/>
      <div className='mt-32 mb-4 flex flex-col gap-4 w-full max-w-[250px]'>
        <h2 className="font-montserratArmRegular text-xl text-dark-hard md:text-xl lg:text-2xl whitespace-nowrap">
          {review.titles[i18n.language]}
        </h2>
        <p className="text-dark-hard w-full">{review.captions[i18n.language]}</p>
        <div className='flex'>
          <BsStarFill className="w-6 h-6 text-lg text-primary" />
          <BsStarFill className="w-6 h-6 text-lg text-primary" />
          <BsStarFill className="w-6 h-6 text-lg text-primary" />
          <BsStarFill className="w-6 h-6 text-lg text-primary" />
          <BsStarFill className="w-6 h-6 text-lg text-primary" />
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
