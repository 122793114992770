import React, { useEffect, useState } from 'react';
import { Link as LinkScroll } from 'react-scroll';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { stagger, useAnimate } from 'framer-motion';

const useMenuAnimation = (isOpen) => {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    const menuAnimations = isOpen
      ? [
        [
          'ul',
          { transform: 'translateX(0%)' },
          { ease: [0.08, 0.65, 0.53, 0.96], duration: 0.6 }
        ],
        [
          'li',
          { transform: 'scale(1)', opacity: 1, filter: 'blur(0px)' },
          { delay: stagger(0.05), at: '-0.1' }
        ]
      ]
      : [
        [
          'li',
          { transform: 'scale(0.5)', opacity: 0, filter: 'blur(10px)' },
          { delay: stagger(0.05, { from: 'last' }), at: '<' }
        ],
        ['ul', { transform: 'translateX(-100%)' }, { at: '-0.1' }]
      ];
    animate([
      [
        'path.top',
        { d: isOpen ? 'M 3 16.5 L 17 2.5' : 'M 2 2.5 L 20 2.5' },
        { at: '<' }
      ],
      ['path.middle', { opacity: isOpen ? 0 : 1 }, { at: '<' }],
      [
        'path.bottom',
        { d: isOpen ? 'M 3 2.5 L 17 16.346' : 'M 2 16.346 L 20 16.346' },
        { at: '<' }
      ],
      ...menuAnimations
    ]);
  }, [animate, isOpen]);

  return scope;
};

export const SidebarContainer = ({ children, isOpen }) => {
  const opacity = isOpen ? 'opacity-100' : 'opacity-0 hidden';
  const top = isOpen ? 'top-0' : '-top-50';
  const zIndex = isOpen ? 'z-99999' : 'z-0';
  const scope = useMenuAnimation(isOpen);

  return (
    <aside
      ref={scope}
      className={`fixed ${zIndex} w-full h-full px-4 bg-dark-light flex top-0 left-0 ${opacity} ${top}`}
    >
      {children}
    </aside>
  );
};

export const CloseIcon = () => <FaTimes color="black" className="p-2 border-2 rounded"/>;

export const Icon = ({ children, ...rest }) => (
  <div
    {...rest}
    className="absolute top-[1.5rem] right-4 bg-transparent text-3xl cursor-pointer outline-none"
  >
    {children}
  </div>
);

export const SidebarWrapper = ({ children }) => (
  <div className="sidebar-wrapper">{children}</div>
);

export const SidebarMenu = ({ children }) => (
  <ul className="block w-100 border-t-2 border-dark-hard text-center">
    {children}
  </ul>
);

export const SidebarLink = ({ navLinkItem, ...rest }) => {
  const isDropDown = (navLinkItem.type && navLinkItem.type === 'dropdown');
  const [dropdown, setDropdown] = useState(false);
  const { t } = useTranslation();

  const toggleDropdownHandler = () => {
    setDropdown((curState) => {
      return !curState;
    });
  };

  return (
    <>
      {!isDropDown
        ? (
          <LinkScroll
            {...rest}
            to={navLinkItem.href}
            className="uppercase border-b-2 py-2 w-[80%] ml-auto hover:border-dark-hard flex items-center justify-center text-[15px] text-dark-hard hover:text-primary cursor-pointer no-underline list-none transition ease-in-out duration-200"
          >
            {t(navLinkItem.name)}
          </LinkScroll>
        )
        : (
          <div
            className="uppercase border-b-2 py-2 w-[80%] ml-auto hover:border-dark-hard flex flex-col items-center justify-center text-[15px] text-dark-hard hover:text-primary cursor-pointer no-underline list-none transition ease-in-out duration-200"
          >
            <button
              className="uppercase flex gap-x-1 items-center"
              onClick={toggleDropdownHandler}
            >
              <span>{t(navLinkItem.name)}</span>
              <MdKeyboardArrowDown/>
            </button>
            <div className={`${
              dropdown ? 'block' : 'hidden'
            } lg:hidden py-4 w-full`}>
              <ul
                className="bg-transparent text-center flex flex-col overflow-hidden">
                {navLinkItem.items.map((page, index) => (
                  <Link
                    key={index}
                    to={page.href}
                    className="uppercase border-b-2 py-2 w-[80%] ml-auto hover:border-dark-hard flex flex-col items-center justify-center text-[15px] text-dark-hard hover:text-primary cursor-pointer no-underline list-none transition ease-in-out duration-200"
                  >
                    {t(page.title)}
                  </Link>
                ))}
              </ul>
            </div>
          </div>
        )}
    </>
  );
};
