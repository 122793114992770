import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import { Sidebar } from './sidebar/Sidebar';
import { getWebSettings } from '../services/index/webSettings';
import { navItemsInfo } from './PageData';

const MainLayout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [webSettings, setWebSettings] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getWebSettings();

        if(data) {
          const { socialLinks, email, phone } = data[0];
          // Combine the fetched data with existing navItemsInfo
          const updatedNavItemsInfo = {
            ...navItemsInfo,
            instagram: {
              name: 'INSTAGRAM',
              type: 'link',
              href: socialLinks.instagram
            },
            facebook: {
              name: 'FACEBOOK',
              type: 'link',
              href: socialLinks.facebook
            },
            twitter: {
              name: 'TWITTER',
              type: 'link',
              href: socialLinks.twitter
            },
            youtube: {
              name: 'YOUTUBE',
              type: 'link',
              href: socialLinks.youtube
            },
            email: {
              name: email,
              type: 'link',
              href: `mailto:${email}`
            },
            phone: {
              name: phone,
              type: 'link',
              href: `tel:${phone}`
            }
            // Add social links, email, and phone number
          };

          setWebSettings(updatedNavItemsInfo);
        }
      } catch (error) {
        console.log('err', error);
      }
    };

    fetchData();
  }, []);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    isOpen ? document.body.style.overflowY = 'hidden' : document.body.style.overflowY = 'auto';
  }, [isOpen]);

  return (
    webSettings && <>
      <Sidebar isOpen={isOpen} toggle={toggle} webSettings={webSettings}/>
      <Header toggle={toggle} webSettings={webSettings}/>
      {children}
      <Footer webSettings={webSettings}/>
    </>
  );
};

export default MainLayout;
