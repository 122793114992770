import React from 'react';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink
} from './SidebarElements';
import { locales } from '../PageData';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const scrollProps = {
  activeClass: 'active',
  smooth: true,
  duration: 500,
  delay: 500,
  spy: true,
  exact: 'true',
  offset: -80
};

export const Sidebar = ({ isOpen, toggle, webSettings }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    navigate(`/${lng}`, { replace: true });
  };

  const filteredData = Object.keys(webSettings)
    .filter((key) => key !== 'phone' && key !== 'email')
    .map((key) => webSettings[key])
    .filter((item) => item.href);

  return (
    <SidebarContainer isOpen={isOpen}>
      <Icon onClick={toggle}>
        <CloseIcon/>
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <ul className='flex w-[80%] ml-auto py-2 justify-center border-b-2'>
            {Object.keys(locales).map((locale) => (
              <li
                key={locale}
                onClick={() => changeLanguage(locale)}
                className={`text-center cursor-pointer uppercase group w-[40px] text-dark-hard last:border-0 border-r-2 ${i18n.language === locale ? 'font-bold' : 'font-normal'}`}>
                <p className='select-none group-hover:md:font-bold'>{locales[locale].title}</p>
              </li>
            ))}
          </ul>
          {filteredData.length > 0 && filteredData.map((item, index) => (
            <SidebarLink key={index.toString()} navLinkItem={item} onClick={toggle} {...scrollProps} />
          ))}
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};
